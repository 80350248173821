import React, { Component } from 'react'
import Link from '../utils/link'

import { Logo } from '../components/icons'

class Header extends Component {

  state = {
    fixed: true,
  }

  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll)
    if (window.scrollY > 0) {
      this.setState({ fixed: false })
    }

  }

  _handleScroll = (event) => {
    let { fixed } = this.state
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop
    let bannerHeight = document.querySelector('.project-banner__container').offsetHeight
    let headerHeight = document.querySelector('.header').offsetHeight
    if (scrollTop > bannerHeight - window.innerHeight + headerHeight) {
      this.setState({ fixed: false })
    } else {
      if (!fixed) {
        this.setState({ fixed: true })
      }
    }
  }

  _closeAccordions = () => {
    document.querySelectorAll('.accordion__item.active .accordion__title').forEach(el => el.click())
    const $firstItem = document.querySelector('.accordion__item')
    const headerHeight = window.innerWidth < 900 ? 62 : 93
    const aboutHeight = document.querySelector('.snap-wrapper__about').offsetHeight
    const sliderHeight = window.innerHeight * 2
    const $snapWrapper = document.querySelector('.snap-wrapper')
    const scrollPos = $firstItem.getBoundingClientRect().top - headerHeight
    window.scrollTo({ top: sliderHeight + 1 })
    $snapWrapper.scrollTo({ top: aboutHeight, behaviour: 'smooth' })
  }

  render() {

    let { offCanvas, fixed } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    return (
      <>
        <header className={`header ${fixed ? 'header--fixed' : ''}`}>
          <div className='header__inner'>
            <div className='blank' />
            <Link to='/' title='Studio Entertech' className='header__logo' {...props}>
              <Logo />
            </Link>
            <button onClick={this._closeAccordions} className={'header__hamburger'}>
              <span className='lines'></span>
            </button>
          </div>
        </header>
        <div className={`header__spacer ${fixed ? '' : 'hide'}`} />
      </>
    )
  }
}

export default Header
